import React from "react";
import Cookies from "js-cookie";

import Box from "../Box";
import RichTextRenderer from "../RichTextRenderer";
import Button from "../Button";

import useGlobalState from "../../../utils/useGlobalState";

const COOKIE_NAME = "gdpr-analytics-enabled";

export default function CookieBanner({ sx, ...otherProps }) {
  const [globalState] = useGlobalState();
  const [isCookieAccepted, setCookieAccepted] = React.useState(true);
  const { sanityMisc } = globalState;

  React.useEffect(() => {
    if (!Cookies.get(COOKIE_NAME)) setCookieAccepted(false);
  }, []);

  const accept = React.useCallback(() => {
    Cookies.set(COOKIE_NAME, true);
    setCookieAccepted(true);
  }, []);

  return isCookieAccepted ? null : (
    <Box
      sx={{
        position: "fixed",
        zIndex: "header",
        left: 0,
        bottom: 0,
        width: "100%",
        display: [null, "flex"],
        fontSize: [0, "body"],
        textAlign: ["center", "left"],
        backgroundColor: "black",
        color: "inverted",
        px: "gridGap",
        py: 3,
        "p:last-of-type": { mb: 0 },
        ...sx,
      }}
      {...otherProps}
    >
      <RichTextRenderer blocks={sanityMisc._rawCookieBannerText} />
      <Button variant="small" onClick={accept} sx={{ ml: [0, 4], mt: [2, 0] }}>
        {sanityMisc.cookieBannerBtn}
      </Button>
    </Box>
  );
}
