import { rem, em } from "polished";

import { fluidProp, fluid, gradientBg } from "./utils/styles";

const heading = {
  lineHeight: "heading",
  fontWeight: "heading",
  "strong, b": {
    fontWeight: "heading",
  },
};

const breakpoints = [em(640), em(1280), em(1440)];
breakpoints.minSize = rem(320);
breakpoints.maxSize = rem(1620);

const colors = {
  text: "#000000",
  body: "#4d4d4d",
  inverted: "#FFFFFF",
  background: "#ECEDF6",
  primary: "#c8134d",
  secondary: "#F7F7F7",
  muted: "#797979",
  error: "#CB0000",
  gray: ["#F3F4FA", "#E8E8E8", "#CCCCD1", "#606060"],
  orange: "#FF6500",
  blue: "#222161",
  red: "#F4004A",
};

const fonts = {
  body: "'Roboto', system-ui, sans-serif",
};

const fontSizes = {
  0: rem(10),
  1: rem(13),
  2: rem(15),
  3: rem(16),
  4: rem(19),
  5: rem(24),
  6: rem(31),
  7: rem(45),
  8: rem(64),
  9: rem(90),
  10: rem(140),
};

fontSizes.small = fontSizes[2];
fontSizes.subtitle = fontSizes[6];
fontSizes.big = fontSizes[10];
fontSizes.body = fluid(
  fontSizes[3],
  fontSizes[4],
  breakpoints.minSize,
  breakpoints.maxSize
);

const fontWeights = {
  body: 300,
  heading: 300,
  bold: 400,
  extraBold: 500,
};

const lineHeights = {
  body: 1.5,
  heading: 1.15,
  input: 1.15,
};

const space = {
  0: rem(0),
  1: rem(5),
  2: rem(10),
  3: rem(20),
  4: rem(35),
  5: rem(60),
  6: rem(120),
  7: rem(180),
};

space.gridGap = "5vw";
space.inputPadding = "0.8em";

const btnHeight = [rem(55), rem(67)];
const inputHeight = [rem(50), rem(55)];

const radii = {
  default: 4,
  card: em(20),
  circle: 99999,
};

const shadows = {
  card: "0 9px 30px rgba(0,0,0,.1)",
  button: "0 9px 20px rgba(0,0,0,.2)",
  header: "0 2px 7px rgba(0,0,0,.04)",
};

const transitions = {
  fast: "150ms",
  normal: "300ms",
};

const zIndices = {
  notification: 900,
  header: 1000,
  modal: 1100,
};

// Variants
const generic = {
  h1: {
    ...heading,
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[7],
        to: fontSizes.big,
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
  h2: {
    ...heading,
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[6],
        to: fontSizes[8],
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
  h3: {
    ...heading,
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[5],
        to: fontSizes[7],
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
  h4: {
    ...heading,
    lineHeight: 1.4,
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[4],
        to: fontSizes[6],
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
  h5: {
    ...heading,
    lineHeight: 1.4,
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[4],
        to: fontSizes[5],
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
  h6: {
    color: "heading",
    fontWeight: "bold",
    lineHeight: "body",
    ...fluidProp(
      {
        prop: "fontSize",
        from: fontSizes[4],
        to: fontSizes[5],
      },
      breakpoints[0],
      breakpoints[2]
    ),
  },
};

const text = {
  default: {
    textTransform: "none",
    fontFamily: "body",
    fontWeight: "body",
    fontSize: "body",
  },
  thumbnailTitle: {
    fontSize: [5, 6, 7],
  },
  caps: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: "extraBold",
    fontSize: fluid(
      fontSizes[1],
      fontSizes[2],
      breakpoints.minSize,
      breakpoints.maxSize
    ),
  },
  inverted: {
    color: "white",
  },
  h1: generic.h1,
  h2: generic.h2,
  h3: generic.h3,
  h4: generic.h4,
  h5: generic.h5,
  h6: generic.h6,
};

const links = {
  default: {
    textDecoration: "underline",
    textDecorationColor: "transparent",
    "&:hover": {
      textDecorationColor: "currentColor",
    },
  },
  caps: {
    ...text.caps,
  },
};

const buttonDefault = {
  color: "text",
  background: "white",
  borderRadius: "circle",
  height: btnHeight,
  py: 3,
  px: [3, 3, 4],
  border: "1px solid transparent",
  ...text.caps,
  "&:hover": {
    textDecorationColor: "transparent",
    color: "white",
  },
  "&:focus": {
    borderColor: "primary",
  },
};

const buttons = {
  default: buttonDefault,
  big: {
    ...buttonDefault,
    height: "auto",
    minWidth: [170, 270],
    py: [3, 3, 4],
  },
  small: {
    ...buttonDefault,
    height: [rem(55), rem(55)],
    px: [3, 3, 3],
  },
  inverted: {
    ...buttonDefault,
    color: "white",
    backgroundColor: "text",
  },
  light: {
    ...buttonDefault,
    color: "body",
    boxShadow: "inset 0 0 0 1px #606060",
  },
  muted: {
    ...buttonDefault,
    background: "gray.2",
    "&:hover": {
      borderColor: "black",
      background: "black",
    },
  },
  smallInverted: {
    ...buttonDefault,
    height: [rem(55), rem(55)],
    px: [3, 3, 3],
    color: "white",
    backgroundColor: "text",
  },
  gradient: {
    ...buttonDefault,
    ...gradientBg,
    color: "white",
    "&:hover": {
      "&:before": {
        opacity: 1,
        visibility: "visible",
        background: "black",
      },
      color: "white",
      textDecorationColor: "transparent",
    },
  },
  reset: {
    background: "none",
    border: "none",
    p: 0,
    "&:before, &:hover:before": {
      display: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },
  link: {
    variant: "buttons.reset",
    ...links.default,
  },
};

const grids = {
  mediaLeft: {
    gridAutoFlow: "column",

    ">:first-of-type": {
      gridColumn: ["start / end", "start / end", "9 / span 5"],
    },
    ">:nth-of-type(2)": {
      gridColumn: ["start / end", "start / end", "start / span 7"],
    },
  },
  mediaRight: {
    gridAutoFlow: "column",

    ">:nth-of-type(1)": {
      gridColumn: ["start / end", "start / end ", "start / span 5"],
    },
    ">:nth-of-type(2)": {
      gridColumn: ["start / end", "start / end ", "span 7"],
    },
  },
  mediaLeftBleed: {
    variant: "grids.mediaLeft",

    ">:nth-of-type(2)": {
      gridColumn: ["1 / -1 ", "1 / -1", "1 / span 8"],
    },
  },
  mediaRightBleed: {
    variant: "grids.mediaRight",
    ">:nth-of-type(2)": {
      gridColumn: ["1 / -1 ", "1 / -1", "span 8"],
    },
  },
  half: {
    "&": {
      gridTemplateColumns: [
        "[start] 1fr [end]",
        "[start] 1fr [end]",
        "repeat(2, [start] 1fr) [end]",
      ],
    },
  },
  threeColumns: {
    "&": {
      gridTemplateColumns: [
        "[start] 1fr [end]",
        "repeat(2, [start] 1fr) [end]",
        "repeat(3, [start] 1fr) [end]",
      ],
    },
  },
  fiveColumns: {
    "&": {
      gridTemplateColumns: [
        "repeat(2, [start] 1fr) [end]",
        "repeat(4, [start] 1fr) [end]",
        "repeat(5, [start] 1fr) [end]",
      ],
    },
  },
};

const forms = {
  input: {
    default: {
      lineHeight: "input",
      color: "white",
      border: "1px solid",
      borderColor: "rgba(255, 255, 255, 0.3)",
      background: "rgba(255, 255, 255, 0.13)",
      p: "inputPadding",
      fontWeight: "body",
      height: inputHeight,
      borderRadius: inputHeight,
      "&:focus": {
        borderColor: "rgba(255, 255, 255, 0.6)",
        background: "rgba(255, 255, 255, 0.35)",
      },
    },
    inverted: {
      variant: "forms.input.default",
      borderColor: "white",
      color: "white",
    },
    small: {
      variant: "forms.input.default",
      fontSize: 4,
    },
    smallInverted: {
      variant: "forms.input.default",
      fontSize: 4,
      borderColor: "white",
      color: "white",
    },
    simple: {
      bg: "transparent",
      borderColor: "transparent",
      borderBottomColor: "black",
      py: "0.3em",
    },
  },
};

const theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  space,
  radii,
  shadows,
  transitions,
  zIndices,
  text,
  links,
  buttons,
  grids,
  forms,
  header: {
    height: 55,
  },
  styles: {
    default: {
      ...generic,
      p: {
        my: "1em",
        "&:first-of-type": {
          mt: 0,
        },
      },
      "h2 + p:first-of-type, h3 + p:first-of-type": {
        mt: 4,
      },
    },
    inverted: {
      variant: "styles.default",
      color: "inverted",
      "h1, h2, h3, h4, h5, h6": {
        color: "inverted",
      },
      "strong, b": {
        color: "inverted",
      },
      blockquote: {
        color: "inverted",
      },
    },
    // Style of articles, use it like: `variant="styles.article"`
    article: {
      color: "body",
      "> *": {
        mx: "auto",
        // See: http://www.simon-li.com/design-and-code/how-to-set-perfect-line-lengths-for-your-webpages/
        maxWidth: fluid(
          rem(270),
          rem(750),
          breakpoints.minSize,
          breakpoints.maxSize
        ),
      },
      "h1, h2, h3, h4, h5, h6": {
        color: "text",
      },
      h1: {
        variant: "text.h2",
        mb: "1em",
      },
      h2: {
        variant: "text.h3",
        mt: "1.3em",
        mb: "1em",
      },
      h3: {
        variant: "text.h5",
        mt: "1.3em",
        mb: "1em",
      },
      h4: {
        variant: "text.h6",
        mt: "1.3em",
        mb: "0.3em",
      },
      "strong, b": {
        fontWeight: "bold",
        color: "text",
      },
      "ul, ol": {
        pl: 3,
      },
      p: {
        my: "1em",
        a: {
          textDecorationColor: (theme) => theme.colors.primary,
          "&:hover": {
            textDecorationColor: "transparent",
          },
        },
      },
      blockquote: {
        borderLeft: "2px solid",
        borderColor: "primary",
        pl: 3,
        mt: "gridGap",
        mb: 4,
        color: "text",
        variant: "text.h4",
      },
    },
  },
};

export default theme;
