import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Facebook from "./Facebook";
import Twitter from "./Twitter";

const SEO = ({
  title: titleProp,
  desc: descriptionProp,
  banner,
  pathname,
  article,
  node,
  htmlAttributes,
  bodyAttributes,
  children,
}) => {
  const { site, sanitySiteSettings } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: { siteUrl, language },
  } = site;

  const {
    openGraphImage,
    author,
    socialLinks,
    title,
    description,
  } = sanitySiteSettings;

  const defaultBanner = openGraphImage.asset.fixed.src;

  const siteTitle = title;
  const defaultDescription = description;

  const homeURL = siteUrl;

  const seo = {
    title: titleProp ? `${titleProp} | ${siteTitle}` : siteTitle,
    description: descriptionProp || defaultDescription,
    image: banner || defaultBanner,
    url: `${siteUrl}${pathname ? "/" + pathname : ""}`,
  };
  const twitter = socialLinks.find(
    (socialLink) => socialLink.type === "twitter"
  );
  const twitterUsername =
    twitter && new URL(twitter.url).pathname.replace("/", "");

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    inLanguage: language,
    mainEntityOfPage: homeURL,
    description: defaultDescription,
    name: siteTitle,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    // datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: defaultBanner,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: defaultBanner,
        },
      },
      datePublished: node._createdAt,
      dateModified: node._updatedAt,
      description: seo.description,
      headline: seo.title,
      inLanguage: language,
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 3,
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={language} {...htmlAttributes} />
        <body {...bodyAttributes} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        {/* {locales.map(locale => {
          const isDefault = locale.config.isDefault
          const langPath = locale.config.path

          return (
            <link
              key={locale.config.code}
              rel="alternate"
              hrefLang={isDefault ? "x-default" : locale.config.ogLang}
              href={
                isDefault
                  ? `${siteUrl}${pathname}`
                  : pathname === "/"
                  ? `${siteUrl}/${langPath}`
                  : `${siteUrl}${pathname.replace(i18n.language, langPath)}`
              }
            />
          )
        })} */}
        {children}
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? "article" : "website"}
        url={seo.url}
        locale={language}
        name={siteTitle}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitterUsername}
      />
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
  htmlAttributes: PropTypes.object,
  bodyAttributes: PropTypes.object,
  children: PropTypes.node,
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: "",
  article: false,
  node: null,
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        language
      }
    }
    sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      openGraphImage {
        asset {
          fixed(width: 800) {
            ...GatsbySanityImageFixed
          }
        }
      }
      socialLinks {
        type
        url
      }
    }
  }
`;
