import React from "react";
import PropTypes from "prop-types";

import Box from "../Box";
import Link from "../Link";
import { gradientBgAnim } from "../../../utils/styles";

const ButtonBase = React.forwardRef(
  ({ sx, disabled, variant, ...otherProps }, ref) => (
    <Box
      ref={ref}
      sx={(theme) => ({
        position: "relative",
        zIndex: 0,
        display: "inline-flex",
        appearance: "none",
        border: "none",
        boxShadow: "none",
        color: "currentColor",
        fontWeight: "inherit",
        cursor: "pointer",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled && "none",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        verticalAlign: "top",
        whiteSpace: "nowrap",
        transition: (theme) => `all ${theme.transitions.fast}`,
        ...theme.buttons[variant], // we don't use variant here since it breaks responsive styles (see: https://github.com/system-ui/theme-ui/issues/490)
        "&:before": {
          position: "absolute",
          display: "block",
          borderRadius: "inherit",
          content: '" "',
          top: "-1px",
          left: "-1px",
          right: "-1px",
          bottom: "-1px",
          opacity: 0,
          visibility: "hidden",
          zIndex: -1,
          ...gradientBgAnim,
          transition: "inherit",
          ...theme.buttons[variant]["&:before"],
        },
        "&:hover": {
          "&:before": {
            opacity: 1,
            visibility: "visible",
          },
          ...theme.buttons[variant]["&:hover"],
        },
        ...sx,
      })}
      type="button"
      disabled={!!disabled}
      {...otherProps}
    />
  )
);

export const Button = React.forwardRef(
  ({ to, href, sx, ...otherProps }, ref) => {
    if (to || href) {
      return (
        <ButtonBase
          as={Link}
          sx={sx}
          ref={ref}
          to={to}
          href={href}
          {...otherProps}
        />
      );
    } else {
      return <ButtonBase as="button" sx={sx} ref={ref} {...otherProps} />;
    }
  }
);

ButtonBase.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

ButtonBase.defaultProps = {
  variant: "default",
};

ButtonBase.displayName = "ButtonBase";
Button.displayName = "Button";

export default Button;
