import React from "react";

import Box from "../Box";

const Heading = React.forwardRef(
  ({ as = "h2", variant, sx, ...otherProps }, ref) => (
    <Box
      ref={ref}
      as={as}
      sx={(theme) => ({
        ...theme.text[variant || as],
        ...(typeof sx === "function" ? sx(theme) : sx),
      })}
      {...otherProps}
    />
  )
);

export default Heading;
