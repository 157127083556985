import { keyframes } from "@emotion/core";
import {
  fluidRange as _fluidRange,
  cover as _cover,
  getValueAndUnit,
} from "polished";

export const ulReset = {
  listStyle: "none",
  paddingLeft: 0,
  marginLeft: 0,
};

export const triangle = (color) => ({
  width: 0,
  height: 0,
  borderLeft: "4px solid transparent",
  borderRight: "4px solid transparent",
  borderBottom: "6px solid",
  borderBottomColor: color,
});

export const centered = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const visuallyHidden = {
  opacity: 0,
  visibility: "hidden",
};

export const visible = {
  opacity: 1,
  visibility: "visible",
};

export const hiddenInput = {
  position: "absolute",
  zIndex: -1,
  opacity: 0,
  width: 0,
};

export const cover = _cover();

export const fadeIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

export const blurOut = keyframes`
  0% {
    opacity: 0;
    filter: blur(30px);
  }
  99% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    filter: none;
  }
`;

export const slideIn = keyframes`
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0%)
  }
`;

const gradientKeyframe = keyframes`
  0% {
    background-position: 0%
  }
  10% {
    background-position: 10%
  }
  50% {
    background-position: 60%
  }
  100% {
    background-position: 100%
  }
`;

export const easeInOutQuint = "cubic-bezier(0.83, 0, 0.17, 1)";
export const easeOutQuint = "cubic-bezier(0.22, 1, 0.36, 1)";

export const fadeInAnim = {
  animation: `${fadeIn} 0.6s 0.2s both`,
};

export const gradientBg = {
  backgroundImage: "linear-gradient(110deg, #222161 0%, #F4004A 100%)",
};

export const gradientBgVertical = {
  backgroundImage: "linear-gradient(180deg, #222161 0%, #F4004A 100%)",
};

export const gradientBgAnim = {
  backgroundImage: "linear-gradient(to right, #F4004A 0%, #222161 100%)",
  backgroundPosition: "0%",
  backgroundSize: "200%",
  animation: `${gradientKeyframe} 6s alternate infinite`,
};

export const gradientText = {
  ...gradientBgAnim,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export const navLinksActive = {
  display: "inline-block",
  textDecoration: "none",
  "&:after": {
    content: "''",
    display: "block",
    transition: (theme) => `all ${theme.transitions.normal}`,
    borderBottom: "1px solid transparent",
    transform: "translateY(2px)",
    pointerEvents: "none",
  },
  "&:hover": {
    color: "currentColor",
    textDecoration: "none",
  },
  '&:hover:after, &[aria-current="page"]:after': {
    borderBottomColor: "currentColor",
    transform: "translateY(0px)",
  },
};

export const borderTop = {
  borderTop: "solid black",
  borderWidth: ["1px", "1px", "2px"],
};

export const borderBottom = {
  borderBottom: "solid black",
  borderWidth: ["1px", "1px", "2px"],
};

export const bgCircle = (theme) => ({
  background:
    "radial-gradient(circle at 100%, #F3F4FA, #F3F4FA 60%,transparent 60%) no-repeat",
  backgroundPositionX: "right",
});

export const fluid = (from, to, minScreen, maxScreen) => {
  const [unitlessFromSize, fromSizeUnit] = getValueAndUnit(from);
  const unitlessToSize = getValueAndUnit(to)[0];
  const unitlessMinScreen = getValueAndUnit(minScreen)[0];
  const unitlessMaxScreen = getValueAndUnit(maxScreen)[0];

  const fromSize = fromSizeUnit ? from : from + "px";

  return `calc(${fromSize} + ${
    unitlessToSize - unitlessFromSize
  } * ((100vw - ${unitlessMinScreen}rem) / ${
    unitlessMaxScreen - unitlessMinScreen
  }))`;
};

export const fluidProp = ({ prop, from, to }, minScreen, maxScreen) => {
  const fromSizeUnit = getValueAndUnit(from)[1];
  const toSizeUnit = getValueAndUnit(to)[1];

  const fromSize = fromSizeUnit ? from : from + "px";
  const toSize = toSizeUnit ? to : to + "px";

  return _fluidRange({ prop, fromSize, toSize }, minScreen, maxScreen);
};

export const carouselStyles = {
  scrollSnapType: "x mandatory",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  "&::-webkit-scrollbar": {
    width: 0,
    height: 0,
  },
};
