export const linkResolver = (link) => {
  return link._type === "link"
    ? {
        href: link.url,
        children: link.label,
      }
    : {
        to: link.reference.slug
          ? link.reference.parentPage
            ? `/${link.reference.parentPage.slug.current}/${link.reference.slug.current}`
            : `/${link.reference.slug.current}`
          : "/", // page can not have a slug, ex: home
        children: link.label && link.label ? link.label : link.reference.title,
      };
};
