import styled from "@emotion/styled";
import { css, get } from "@theme-ui/css";
import { createShouldForwardProp } from "@styled-system/should-forward-prop";

const shouldForwardProp = createShouldForwardProp(["sx", "variant"]);

const sx = (props) => css(props.sx)(props.theme);
const base = (props) => css(props.__css)(props.theme);
const variant = ({ theme, variant, __themeKey = "variants" }) =>
  css(get(theme, __themeKey + "." + variant, get(theme, variant)));

const Box = styled("div", { shouldForwardProp })(
  base,
  variant,
  sx,
  (props) => props.css
);

export default Box;
