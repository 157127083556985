/** @jsx jsx */
import { jsx } from "@theme-ui/core";
import React from "react";
import { GatsbyImage as Img } from "@gromy/gatsby-plugin-image/compat";

const Image = React.forwardRef(
  (
    {
      width,
      height,
      sx,
      extension,
      alt,
      fluid,
      fixed,
      sizes,
      imgProps,
      ...otherProps
    },
    ref
  ) => {
    if (extension === "svg") {
      return (
        <img
          sx={(theme) => ({
            maxWidth: "100%",
            width: width || "100%",
            height: height || "auto",
            ...(typeof sx === "function" ? sx(theme) : sx),
          })}
          src={fluid?.src || fixed?.src}
          alt={alt}
          {...otherProps}
          ref={ref}
        />
      );
    } else {
      return (
        <Img
          sx={(theme) => ({
            maxWidth: "100%",
            width: width || "100%",
            height: height || "auto",
            ...(typeof sx === "function" ? sx(theme) : sx),
          })}
          alt={alt}
          fluid={
            fluid && {
              ...fluid,
              aspectRatio: 1 / fluid.aspectRatio,
              sizes: sizes
                ? sizes
                : width
                ? `(max-width: ${width}) 100vw, ${width}px`
                : fluid.sizes,
            }
          }
          fixed={fixed}
          sizes={
            sizes
              ? sizes
              : width
              ? `(max-width: ${width}) 100vw, ${width}px`
              : undefined
          }
          {...imgProps}
          {...otherProps}
          ref={ref}
        />
      );
    }
  }
);

Image.displayName = "Image";

export default Image;
