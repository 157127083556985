import React from "react";
import { Global } from "@emotion/core";
import { bgCircle } from "../utils/styles";

const GlobalStyles = () => (
  <Global
    styles={(theme) => ({
      html: {
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        textRendering: "optimizeLegibility",
      },
      body: {
        fontSize: theme.fontSizes.body,
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.body,
        lineHeight: theme.lineHeights.body,
      },
      "b, strong": {
        fontWeight: theme.fontWeights.bold,
      },
      hr: {
        border: "none",
      },
      a: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          textDecoration: "underline",
          color: theme.colors.primary,
          textDecorationColor: "inherit",
        },
      },
      "@supports (text-decoration-color: transparent)": {
        a: {
          textDecoration: "underline transparent",
        },
      },
      "button, a": {
        transition: "all 150ms",
      },
      "img, svg": {
        display: "block",
        maxWidth: "100%",
      },
      svg: {
        maxHeight: "100%",
      },
      "input, textarea, select": {
        appearance: "none",
        width: "100%",
        maxWidth: "100%",
        border: "1px solid",
        "&:focus": {
          outline: "none",
        },
      },
      address: {
        fontStyle: "normal",
      },
      /* Remove default margin */
      [`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol,
        li,
        figure,
        figcaption,
        blockquote,
        dl,
        dd
      `]: {
        margin: 0,
      },
      ".js-focus-visible :focus:not(.focus-visible)": {
        outline: "none",
      },
      ".bg-circle": bgCircle(theme),
    })}
  />
);

export default GlobalStyles;
