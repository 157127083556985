import React from "react";
import Grid from "../Grid";
import Col from "./Col";

const GridRow = React.forwardRef(
  ({ subGrid, columns = 12, sx, variant, ...otherProps }, ref) => (
    <Grid
      {...otherProps}
      ref={ref}
      variant={variant}
      sx={{
        gridTemplateColumns: subGrid
          ? `repeat(${columns}, [start] 1fr) [end]`
          : `0 repeat(${columns}, [start] 1fr) [end] 0`,
        ...sx,
      }}
    />
  )
);

GridRow.Col = Col;

export default GridRow;
