import React from "react";

import GridRow from "../GridRow";
import Box from "../Box";
import Link from "../Link";
import List from "../List";
import Text from "../Text";

import useGlobalState from "../../../utils/useGlobalState";

import { linkResolver } from "../../../utils/links";

import { ReactComponent as Logo } from "../../../images/logo.svg";

const Footer = ({ sx, ...otherProps }) => {
  const [globalState] = useGlobalState();
  const { sanityNavigation, sanitySiteSettings, allSanityOffice } = globalState;
  const { socialLinks } = sanitySiteSettings;
  const navLinks = sanityNavigation.footer;
  const offices = allSanityOffice.nodes;
  // const resolvedNavItems = navItems.filter(linkExists).map(linkResolver);

  return (
    <GridRow
      as="footer"
      sx={{
        position: "relative",
        bg: "black",
        color: "white",
        py: [4, "gridGap"],
        gridRowGap: 4,
        ...sx,
      }}
      {...otherProps}
    >
      {/* Logo */}
      <GridRow.Col sx={{ mb: [5, 7] }}>
        <Link
          to="/"
          sx={{
            display: "block",
            maxWidth: "24vw",
            svg: { height: "auto" },
          }}
        >
          <Logo width="130" height="21" />
        </Link>
      </GridRow.Col>

      <GridRow.Col
        gridColumn="start / end"
        sx={{ borderBottom: "1px solid currentColor", pb: 4 }}
      >
        <List
          gap={[4, 4, 3]}
          sx={{
            gridAutoColumns: "minmax(min-content, auto)",
            gridAutoFlow: ["row", "row", "column"],
          }}
        >
          {offices.map((office, i) => (
            <Box
              as="li"
              key={office.title}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Text variant="caps">{office.title}</Text>
              <Link variant="caps" href={`mailto:${office.address.email}`}>
                {office.address.email}
              </Link>
            </Box>
          ))}
        </List>
      </GridRow.Col>

      <GridRow.Col gridColumn={["start / end", "start / end", "start / 9"]}>
        <List
          gap={3}
          horizontal
          sx={{
            gridAutoFlow: ["row", "column"],
            gridTemplateColumns: ["1fr 1fr", "none"],
          }}
        >
          <li>
            <Text variant="caps">© {new Date().getFullYear()} Contino</Text>
          </li>
          {navLinks.map(linkResolver).map((navLink, i) => (
            <li key={navLink.to || navLink.href}>
              <Link {...navLink} variant="caps" />
            </li>
          ))}
        </List>
      </GridRow.Col>

      <GridRow.Col
        gridColumn={["start / end", "start / end", "9 / end"]}
        sx={{ justifySelf: ["auto", "flex-end"] }}
      >
        <List gap={3} horizontal>
          {socialLinks.map((socialLink, i) => (
            <li key={socialLink.url}>
              <Link
                href={socialLink.url}
                sx={{
                  display: "block",
                  color: "white",
                  p: "7px",
                  border: "1px solid currentColor",
                  borderRadius: "circle",

                  "&:hover": {
                    color: "primary",
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "currentColor",
                    width: "13px",
                    height: "13px",
                  }}
                  style={{
                    mask: `url("/icons/${socialLink.type}.svg") center center no-repeat`,
                    WebkitMask: `url("/icons/${socialLink.type}.svg") center center no-repeat`,
                  }}
                  aria-label={socialLink.type}
                />
              </Link>
            </li>
          ))}
        </List>
      </GridRow.Col>
    </GridRow>
  );
};

export default Footer;
