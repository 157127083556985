import React from "react";

const GlobalStateContext = React.createContext();

export const GlobalStateProvider = ({ initialState, children }) => {
  const [state, setGlobalState] = React.useState(initialState);

  return (
    <GlobalStateContext.Provider value={{ state, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = (initialState) => {
  const { state, setGlobalState } = React.useContext(GlobalStateContext);

  if (initialState) {
    const newState = { ...state, ...initialState };
    setGlobalState(newState);

    return [newState, setGlobalState];
  }

  return [state, setGlobalState];
};

export default useGlobalState;
