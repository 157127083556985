import React from "react";
import SanityBlockContent from "@sanity/block-content-to-react";
import mergeSerializers from "@sanity/block-content-to-hyperscript/lib/mergeSerializers";
import getDefaultSerializers from "@sanity/block-content-to-hyperscript/lib/serializers";

import {
  Link,
  EmailLink,
  FileLink,
  Container,
  Block,
  Image,
  Embed,
  MediaBlock,
  Code,
} from "./sanityBlocks";

const serializers = {
  ...getDefaultSerializers(React.createElement).defaultSerializers,
  types: {
    block: Block,
    simpleMediaBlock: MediaBlock,
    embed: Embed,
    imageArticle: Image,
    code: Code,
  },
  marks: {
    link: Link,
    fileLink: FileLink,
    emailLink: EmailLink,
  },
  container: Container,
};
export default function RichTextRenderer({
  serializers: serializersProp = {},
  ...otherProps
}) {
  return (
    <SanityBlockContent
      serializers={mergeSerializers(serializers, serializersProp)}
      renderContainerOnSingleChild
      {...otherProps}
    />
  );
}
