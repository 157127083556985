import React from "react";
import Box from "../Box";

const Grid = React.forwardRef(
  ({ sx, gap = (theme) => theme.space.gridGap, ...otherProps }, ref) => {
    return (
      <Box
        sx={(theme) => ({
          display: "grid",
          gridGap: gap,
          "@supports not (display: grid)": {
            display: "flex",
            flexWrap: "wrap",
          },
          ...sx,
        })}
        __themeKey="grids"
        {...otherProps}
        ref={ref}
      />
    );
  }
);

Grid.displayName = "Grid";

export default Grid;
