import React from "react";
import { Link as GatsbyLink } from "gatsby";
import Box from "../Box";

export default function Link({ to, href, variant = "default", ...otherProps }) {
  const commonProps = {
    __themeKey: "links",
    variant,
    ...otherProps,
  };

  const handleClick = React.useCallback(
    (e) => {
      const targetEl = document.querySelector(href);

      if (targetEl) {
        e.preventDefault();

        targetEl.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [href]
  );
  if (href && href.match(/^#/)) {
    return <Box as="a" href={href} onClick={handleClick} {...commonProps} />;
  }

  return href ? (
    href.match(/^\//) ? (
      <Box as={GatsbyLink} to={href} {...commonProps} />
    ) : (
      <Box
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...commonProps}
      />
    )
  ) : (
    <Box as={GatsbyLink} to={to} {...commonProps} />
  );
}
