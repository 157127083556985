import React from "react";

import Box from "../Box";

export default function Text({
  as = "span",
  variant = "default",
  sx,
  ...otherProps
}) {
  return <Box as={as} variant={`text.${variant}`} sx={sx} {...otherProps} />;
}
