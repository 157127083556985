import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import SanityBlockContent from "@sanity/block-content-to-react";

import GridRow from "../../../commons/GridRow";
import ImageRaw from "../../../commons/Image";
import LinkRaw from "../../../commons/Link";
import Box from "../../../commons/Box";
import Heading from "../../../commons/Heading";
import Button from "../../../commons/Button";
import { cover } from "../../../../utils/styles";
import { linkResolver } from "../../../../utils/links";

import CodeBlock from "./Code";
import RichTextRenderer from "../";

export const Image = ({ sx, node }) => {
  const data = useStaticQuery(query);
  const image = (sx = {}) => (
    <ImageRaw
      fluid={getFluidGatsbyImage(
        node.asset,
        { maxWidth: 1280 },
        {
          projectId: data.site.siteMetadata.sanityProjectId,
          dataset: "production",
        }
      )}
      alt={node.alt}
      sx={sx}
    />
  );

  return node.href ? (
    <LinkRaw
      href={node.href}
      sx={{
        display: "block",
        ...sx,
      }}
    >
      {image()}
    </LinkRaw>
  ) : (
    image(sx)
  );
};

export const Link = ({ mark, children }) => {
  const { blank, href } = mark;

  return blank ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <a href={href}>{children}</a>
  );
};

export const EmailLink = ({ mark, children }) => {
  const { href } = mark;
  const emailLink = "mailto:" + href;

  return <a href={emailLink}>{children}</a>;
};

export const FileLink = ({ mark, children }) => {
  const { asset } = mark;

  return (
    <a href={asset.url} target="_blank" rel="noopener noreferrer" download>
      {children}
    </a>
  );
};

export const Container = (props) => {
  return <Box variant="styles.default">{props.children}</Box>;
};

export const Block = SanityBlockContent.defaultSerializers.types.block;

export const Embed = ({ node }) => {
  const elRef = React.useRef();
  const [iframeRatio, setIframeRatio] = React.useState(0);

  React.useEffect(() => {
    const iframeEl = elRef.current.querySelector("iframe");

    setIframeRatio(iframeEl.height / iframeEl.width);
  }, []);

  return (
    <Box>
      <Box
        dangerouslySetInnerHTML={{ __html: node.code }}
        ref={elRef}
        style={{ paddingBottom: `${iframeRatio * 100}%`, height: 0 }}
        sx={{
          position: "relative",
          iframe: { ...cover, width: "100%", height: "100%" },
        }}
      />
    </Box>
  );
};

export const MediaBlock = ({ node }) => {
  return (
    <GridRow
      as="section"
      variant="half"
      gap={0}
      sx={{
        background: (theme) =>
          theme.colors[node.background] || theme.colors.text,
        textAlign: ["center", "start"],
        maxWidth: ["none", "80vw"],
        my: "gridGap",
      }}
    >
      {node.image && <Image node={node.image} sx={{ alignSelf: "center" }} />}
      <Box sx={{ px: "gridGap", py: 4 }}>
        <Box
          variant={`${
            node.background !== "background"
              ? "styles.inverted"
              : "styles.default"
          }`}
        >
          <Heading as="h3" sx={{ color: "black" }}>
            {node.title}
          </Heading>
          <RichTextRenderer blocks={node.description} />
        </Box>
        {node.button && !!node.button.length && (
          <Box sx={{ mt: 4 }}>
            {node.button.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} {...link} />
            ))}
          </Box>
        )}
      </Box>
    </GridRow>
  );
};

export const Code = CodeBlock;

const query = graphql`
  query sanityBlock {
    site {
      siteMetadata {
        sanityProjectId
      }
    }
  }
`;
