import React from "react";
import { ThemeProvider } from "@theme-ui/core";
import { SkipNavLink } from "@reach/skip-nav";
import { useStaticQuery, graphql } from "gatsby";

import "typeface-roboto";
import "modern-normalize/modern-normalize.css";
import "@reach/skip-nav/styles.css";
import "@snap-carousel/core/dist/styles.css";

import SEO from "./commons/SEO";
import Footer from "./commons/Footer";
import CookieBanner from "./commons/CookieBanner";
import GlobalStyles from "./GlobalStyles";
import { GlobalStateProvider } from "../utils/useGlobalState";
import theme from "../theme";

function Root({ children, pageContext, location }) {
  // Fetch global data (things in header, footer, etc.)
  // We fetch it here so it's bundled once instead of in every pages package
  const data = useStaticQuery(graphql`
    query Commons {
      sanitySiteSettings {
        title
        description
        author
        openGraphImage {
          asset {
            fixed(width: 800) {
              ...GatsbySanityImageFixed
            }
          }
        }
        socialLinks {
          url
          type
        }
      }
      allSanityOffice(sort: { fields: _createdAt, order: ASC }) {
        nodes {
          title
          address {
            city
            email
            postcode
            street
          }
        }
      }
      sanityNavigation {
        header {
          ...NavLink
        }
        headerButton {
          ...Link
        }
        footer {
          ...Link
        }
      }
      sanityMisc {
        _rawCookieBannerText(resolveReferences: { maxDepth: 5 })
        cookieBannerBtn
      }
    }
  `);

  return (
    <GlobalStateProvider initialState={data}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <SEO />
        <SkipNavLink />
        {children}
        <Footer />
        <CookieBanner />
      </ThemeProvider>
    </GlobalStateProvider>
  );
}

export default Root;
