import React from "react";
import Grid from "../Grid";

import { ulReset } from "../../../utils/styles";

const List = ({ horizontal, gap = 1, sx, ...otherProps }) => {
  return (
    <Grid
      as="ul"
      sx={{
        ...ulReset,
        gridAutoRows: !horizontal && "minmax(min-content, max-content)",
        gridAutoColumns: horizontal && "minmax(min-content, max-content)",
        gridAutoFlow: horizontal ? "column" : "row",
        ...sx,
      }}
      gap={gap}
      {...otherProps}
    />
  );
};

export default List;

List.defaultProps = {
  alignItems: "center",
  horizontal: false,
};
