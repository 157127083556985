import React from "react";
import Box from "../../Box";

const Col = React.forwardRef(
  ({ sx = {}, fullWidth = true, bleed, gridColumn, ...otherProps }, ref) => (
    <Box
      ref={ref}
      sx={(theme) => ({
        p: [`calc(${theme.space[3]} / 2)`, `calc(${theme.space[4]}  / 2)`],
        "@supports (display: grid)": {
          p: 0,
          ...(fullWidth && { gridColumn: "start / end" }),
          ...(gridColumn && { gridColumn }),
          ...(bleed && { gridColumn: "1 / -1" }),
          ...sx,
        },
        ...sx,
      })}
      {...otherProps}
    />
  )
);

export default Col;
