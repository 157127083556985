import React from "react";

import Box from "../../../../commons/Box";

const resolveLanguageName = (language) => {
  switch (language) {
    case "golang":
      return "go";
    default:
      return language;
  }
};

export default function Code({ node }) {
  React.useEffect(() => {
    import("./Prism").then((module) => {
      const Prism = module.default;
      Prism.highlightAll();
    });
  }, []);

  return (
    <Box>
      <Box
        as="pre"
        sx={{
          borderRadius: ".5em",
          border: ".3em solid hsl(0, 0%, 33%)",
          boxShadow: "1px 1px .5em black inset",
          overflow: "auto",
          padding: "1em",
          background: "hsl(0, 0%, 8%)",
        }}
      >
        <Box
          as="code"
          className={`language-${resolveLanguageName(node.language)}`}
          dangerouslySetInnerHTML={{ __html: node.code }}
        />
      </Box>
    </Box>
  );
}
