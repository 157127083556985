/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export { default as wrapPageElement } from "./wrapPageElement";

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }
  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.onload = () => {
    window.dataLayer &&
      window.dataLayer.push({
        event: "gtm.js",
        "gtm.start": new Date().getTime(),
        "gtm.uniqueEventId": 0,
      });
  }; // this part ensures PageViews is always tracked
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.ANALYTICS_ID}`;

  document.head.appendChild(script);
}

export const onClientEntry = () => {
  return new Promise(async (resolve, reject) => {
    // We preload this as it's doing title animations which must happen asap
    // only on homepage for optimisation
    if (window.location.pathname === "/") {
      const { default: Splitting } = await import(
        "splitting/dist/splitting-lite"
      );
      window.Splitting = Splitting;
    }

    // GoogleTagManager
    document.addEventListener("DOMContentLoaded", () => {
      /** init gtm after 3500 seconds - this could be adjusted */
      setTimeout(initGTM, 3500);
    });
    document.addEventListener("scroll", initGTMOnEvent);
    document.addEventListener("mousemove", initGTMOnEvent);
    document.addEventListener("touchstart", initGTMOnEvent);

    // Polyfill.io stuff
    window.__polyfillio__ = () => {
      resolve();
    };

    const features = [];

    if (!("fetch" in window)) {
      features.push("fetch");
    }

    if (!("IntersectionObserver" in window)) {
      features.push("IntersectionObserver");
    }

    if (features.length) {
      const s = document.createElement("script");
      s.src = `https://cdn.polyfill.io/v3/polyfill.min.js?features=${features.join(
        ","
      )}&callback=__polyfillio__`;
      s.async = true;
      s.onerror = reject;
      document.head.appendChild(s);
    } else {
      resolve();
    }
  });
};
